exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-budmadness-js": () => import("./../../../src/pages/budmadness.js" /* webpackChunkName: "component---src-pages-budmadness-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-template-blog-post-js": () => import("./../../../src/templates/template-blog-post.js" /* webpackChunkName: "component---src-templates-template-blog-post-js" */),
  "component---src-templates-template-kiosk-js": () => import("./../../../src/templates/template-kiosk.js" /* webpackChunkName: "component---src-templates-template-kiosk-js" */),
  "component---src-templates-template-persona-js": () => import("./../../../src/templates/template-persona.js" /* webpackChunkName: "component---src-templates-template-persona-js" */),
  "component---src-templates-template-standard-js": () => import("./../../../src/templates/template-standard.js" /* webpackChunkName: "component---src-templates-template-standard-js" */)
}

